import React from 'react';

export const LeadershipGovernors = () => {
  return (
    <div className="flex md:flex-row flex-col items-start md:space-x-48 space-x-0 space-y-10 md:space-y-0 w-full">
      <p className="font-semibold md:text-36 text-30 text-left md:w-32 w-full">
        LAKL <br className="hidden md:block" />
        Regional <br className="hidden md:block" />
        Governors
      </p>

      <div className="w-full flex items-start md:flex-row flex-col md:justify-between justify-start">
        <div className="w-full space-y-10">
          {/*  US – California */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 w-full">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              US – California
            </p>

            <div className="md:space-y-4 space-y-2 w-full">
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Michael Chung (LA)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Kay Suh (Los Angeles) 
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Monica Min (Los Angeles)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Cheryl Kang Prout (Orange County)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Mandy Kim (Orange County)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Chang Lim (Orange County)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Josh Ji (Orange County)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Jang Im (Northern California)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Stephan Choo (Northern California)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Lisa Yun Pruitt (San Diego)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Sam You (San Diego)
              </p>
            </div>
          </div>

          {/* US – Pacific Northwest */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 w-full">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              US – Pacific Northwest
            </p>

            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
              Edwina Kye (Seattle)
            </p>
          </div>

          {/*  US – Central */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 w-full">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              US – Central
            </p>

            <div className="md:space-y-4 space-y-2 w-full">
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Lisa Ellingson (Minneapolis)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Peter J. Huh (Chicago)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Jay Choi (Denver)
              </p>
            </div>
          </div>

          {/*  US Pacific Southwest */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 w-full">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              US Pacific Southwest
            </p>

            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
              Ju Park (Pacific) 
            </p>
          </div>

          {/*  US – East Coast */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 w-full">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              US – East Coast
            </p>

            <div className="md:space-y-4 space-y-2 w-full">
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Jung Wook Lee (Atlanta)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Marianna Yang (Boston)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Paul Yoon (New Jersey)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Sophia Soejung Kim (New York)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Joe DeMarco (New York)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Marianna Yang (Boston)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Gene Kang (New York)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Rina Kim (DC)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Leah Min (DC)
              </p>
            </div>
          </div>

          {/*  Brazil  */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 w-full">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              Brazil 
            </p>

            <div className="md:space-y-4 space-y-2 w-full">
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Min Gon Kim 
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Shin Jae Kim 
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                In Hee Cho
              </p>
            </div>
          </div>
        </div>

        <div className="md:space-y-20 md:pl-10 pl-0 space-y-0 ">
          {/* Canada */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 pt-10 md:pt-0">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              Canada
            </p>

            <div className="md:space-y-4 space-y-2">
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Ed Kang (Toronto)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Benjamin Na (Toronto)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Frank Sur (Calgary)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Kwang Lim (Vancouver)
              </p>
              <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 text-left">
                Yong-Jae Kim (Vancouver)
              </p>
            </div>
          </div>

          {/* France  */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 pt-10 md:pt-0">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              France
            </p>

            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0">
              Habbine Estelle Kim
            </p>
          </div>

          {/* Italy */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 pt-10 md:pt-0">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              Italy
            </p>

            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0">
              Soo Youn Kim
            </p>
          </div>

          {/* United Kingdom */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 pt-10 md:pt-0">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              United
              <br className="hidden md:block" />
              Kingdom
            </p>

            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0">
              Tae Young Min
            </p>
          </div>

          {/* Austria  */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 pt-10 md:pt-0">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              Austria
            </p>

            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0">
              Suzy Park
            </p>
          </div>

          {/* Germany   */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 pt-10 md:pt-0">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              Germany
            </p>

            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0">
              Raphael Suh
            </p>
          </div>

          {/* Luxembourg    */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 pt-10 md:pt-0">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              Luxembourg
            </p>

            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0">
              Chan Park
            </p>
          </div>

          {/* Argentina */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 pt-10 md:pt-0">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              Argentina
            </p>

            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0">
              Hugo Jong Uk Woo
            </p>
          </div>

          {/* Auckland */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 pt-10 md:pt-0">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              Auckland
            </p>

            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0">
              Hak Jun Lee
            </p>
          </div>

          {/* Sydney */}
          <div className="flex flex-col items-start md:space-y-10 space-y-6 pt-10 md:pt-0">
            <p className="font-medium md:text-32 text-24 text-gray-600 text-left">
              Sydney
            </p>

            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0">
              James Jung
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
