import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Section } from '../components/Section';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { fetcher } from '../plugins/react-query';
import { Seminar } from '../types';

export const SeminarPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: seminar } = useQuery<Seminar>(`/seminars/${id}`, fetcher);

  if (!seminar) return <></>;
  return (
    <Section className="max-w-3xl">
      <h1 className="text-24 font-bold capitalize break-all">
        {seminar.title}
      </h1>
      <time className="text-15 text-gray-500 mb-8">
        {utcToLocalFormat(seminar.createdAt, MomentFormat.LL)}
      </time>
      <FroalaEditorView model={seminar.context} />
    </Section>
  );
};
