import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../components/Button';
import { TextField } from '../components/TextField';
import { useAuth } from '../hooks';
import { api } from '../plugins/axios';

interface FormValues {
  password: string;
  confirmPassword: string;
}

export const PasswordChangePage = () => {
  const { logout } = useAuth();
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <div className="w-full space-y-6 pt-10 md:pt-20 pb-40 max-w-md mx-auto px-4">
      <h1 className="text-32 font-bold text-center pb-7 md:pb-14">
        Reset Password
      </h1>
      <div className="w-full space-y-8 flex flex-col justify-center">
        <TextField
          label="Password"
          type="password"
          helper={errors.password?.message}
          minLength={6}
          {...register('password', {
            required: 'This field is required',
            minLength: {
              value: 6,
              message: 'Please enter at least 6 characters',
            },
          })}
        />
        <TextField
          label="Confirm Password"
          type="password"
          helper={errors.confirmPassword?.message}
          minLength={6}
          {...register('confirmPassword', {
            required: 'This field is required',
            minLength: {
              value: 6,
              message: 'Please enter at least 6 characters',
            },
          })}
        />
        <Button
          text="Save"
          className="outlined-brand-1"
          disabled={!watch('password') || !watch('confirmPassword')}
          onClick={handleSubmit((data) => {
            if (watch('password') !== watch('confirmPassword')) {
              alert(`Password doesn't match`);
            } else {
              api
                .patch('/users/change-password', data)
                .then(async () => {
                  await alert('Success');
                  await logout();
                })
                .catch((e) => alert(e));
            }
          })}
        />
      </div>
    </div>
  );
};
