import { ComponentType, FC } from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import { useAuth } from '../hooks';
import { languageState } from '../plugins/ridge';
import { useQuery } from 'react-query';
import { User } from '../types';
import { fetcher } from '../plugins/react-query';

export interface AuthRouteProps {
  path: string;
  component: ComponentType;
  guestOnly?: boolean;
}

export const AuthRoute: FC<AuthRouteProps> = ({
  path,
  component: Component,
  guestOnly = false,
}) => {
  const { pathname } = useLocation();
  const { authenticated } = useAuth();
  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: authenticated,
    refetchOnWindowFocus: true,
  });
  const language = languageState.useValue();

  const isCheckNewConsentAgreed = (me: User) => {
    if (
      me.dataCollectionConsent &&
      me.thirdPartyDataConsent &&
      me.termsOfServiceConsent
    )
      return true;
    return false;
  };

  return (
    <Route
      path={path}
      render={() => {
        if (guestOnly && authenticated) {
          return (
            <Redirect
              to={language === 'KO' ? '/greeting?local=KO' : '/?local=EN'}
            />
          );
        }
        if (!guestOnly && !authenticated) {
          return <Redirect to="/signin" />;
        }
        if (me && isCheckNewConsentAgreed(me) === false) {
          return <Redirect to="/new-consent" />;
        }
        return <Component />;
      }}
    />
  );
};
