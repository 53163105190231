import { parse, stringify } from 'qs';
import { useHistory, useLocation } from 'react-router';
import { api } from './plugins/axios';
import { tokenState } from './plugins/ridge';

export const useAuth = () => {
  const [token, setToken] = tokenState.use();
  const { push } = useHistory();
  const authenticated = token !== null;
  const signup = (data: any) =>
    api
      .post('/auth/signup', data)
      .then(({ data: { token } }) => setToken(token));
  const login = (data: any) => {
    api
      .post('/auth/login', data)
      .then(({ data: { token } }) => {
        api
          .post('/auth/is-user-agreed-new-consent', data)
          .then(({ data: isAgreed }) => {
            if (isAgreed === false) {
              push('/new-consent');
              setToken(token);
            } else if (isAgreed === true) {
              setToken(token);
            }
          })
          .catch((e) => {
            e.response.status === 401 && alert('Incorrect email or password');
          });
      })
      .catch((e) => {
        e.response.status === 401 && alert('Incorrect email or password');
      });
  };
  const logout = () => tokenState.reset();
  return { token, authenticated, signup, login, logout };
};

export function useQueryString(queryObject: any = {}) {
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });
  const searchObject = {
    page: 1,
    limit: 10,
    sort: { id: 'DESC' },
    ...parsed,
    ...queryObject,
  };
  return stringify(searchObject, { addQueryPrefix: true, encode: false });
}
