import React from 'react';

export const LeadershipDirectors = () => {
  return (
    <div className="flex md:flex-row flex-col items-start md:space-x-48 space-x-0 space-y-10 md:space-y-0 w-full">
      <p className="font-semibold md:text-36 text-30 md:w-32 w-full text-left">
        Directors
      </p>

      <div className="w-full flex items-start md:flex-row flex-col md:justify-between justify-start">
        {/* Korea */}
        <div className="flex flex-col items-start md:space-y-10 space-y-6 md:w-2/3 w-full ">
          <p className="font-medium md:text-32 text-24 text-gray-600">Korea</p>

          <div className="md:space-y-4 space-y-2 w-full">
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Kwon Hoe Kim
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Eunice Kim
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Hyung Keun Lee
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Aram Choi
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Seung Cheol Baek
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Jaewoo Kwak
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Jae-hyong Woo
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Woo Young Choi
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Young Jin Jung
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Robin J. Baik
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Seung Min Lee
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              DK Donghyun Kim
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Mino Han
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Moses Sye
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Myung-Ahn Kim
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Hong-Joong Kim
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Minseok Kim
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Moonhui Eileen Kim
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Si Young Kim
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Jung Min Jo
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Shinyoung Choi
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Chung Jin Chung
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Ahyoung Huh
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Sun Young Park
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Daniel Woojin Chang
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Yeana Lee
            </p>
          </div>
        </div>

        {/* Overseas */}
        <div className="flex flex-col items-start md:space-y-10 space-y-6 md:ml-10 ml-0 pt-20 md:pt-0">
          <p className="font-medium md:text-32 text-24 text-gray-600">
            Overseas
          </p>

          <div className="md:space-y-4 space-y-2 w-full">
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Philip Cho
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Shirley Cho
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Jiwon Ha
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Cristina Hwang
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              John Min
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Kim Eric Möric
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Wade Pyun
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Peter Rho
            </p>
            <p className="md:text-17 text-15 text-gray-600 flex-shrink-0 md:w-40 text-left">
              Mary Jane Yoon
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
