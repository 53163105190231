import React, { FC, useState } from 'react';
import { AnimationLayout } from './AnimationLayout';
import { MainPopup } from '../types';

interface MainPopupModalProps {
  open: boolean;
  onClose: () => void;
  mainPopups: MainPopup[];
}

export const MainPopupModal: FC<MainPopupModalProps> = ({
  open,
  onClose,
  mainPopups,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [openTest, setOpenTest] = useState<boolean>(true);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === mainPopups.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? mainPopups.length - 1 : prevIndex - 1,
    );
  };

  const currentPopup = mainPopups[currentIndex];
  return (
    <AnimationLayout open={open} onClose={() => setOpenTest}>
      <div
        className="relative my-20 max-w-2xl w-full text-left"
        // style={{ maxWidth: '500px', maxHeight: '500px' }}
      >
        <div className="absolute -top-12 -right-0 z-10">
          <button
            className="p-2 bg-white text-gray-500 hover:text-gray-700 rounded-full focus:outline-none shadow-md w-10 h-10"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            ✕
          </button>
        </div>

        <div
          className="relative  w-full overflow-hidden rounded-lg"
          // style={{ aspectRatio: '3/2' }}
        >
          <div className="p-2 bg-white">
            {/* 흰색 배경과 공백을 위한 div */}
            <img
              src={currentPopup.filename}
              className="w-full h-full object-contain cursor-pointer rounded-lg"
              alt="logo"
              onClick={() => {
                currentPopup.redirectUrl &&
                  window.open(currentPopup.redirectUrl, '_blank');
              }}
            />
          </div>

          <div className="absolute bottom-4 right-4 flex space-x-2">
            {mainPopups.length !== 1 && (
              <>
                <button
                  className="p-2 w-10 h-10 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full shadow-lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePrev();
                  }}
                >
                  <span>&#9664;</span>
                </button>
                <button
                  className="p-2 w-10 h-10 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full shadow-lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNext();
                  }}
                >
                  <span>&#9654;</span>
                </button>
              </>
            )}
          </div>
        </div>

        <div className="flex justify-center mt-2 space-x-2">
          {mainPopups.length !== 1 &&
            mainPopups.map((_, index) => (
              <span
                key={index}
                className={`block ${
                  currentIndex === index
                    ? 'w-6 h-2 bg-gray-100'
                    : 'w-2 h-2 bg-gray-400'
                } rounded-full`}
              ></span>
            ))}
        </div>
      </div>
    </AnimationLayout>
  );
};
