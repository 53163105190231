import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button } from '../components/Button';
import { H1 } from '../components/H1';
import { Section } from '../components/Section';

import { api } from '../plugins/axios';
import { Checkbox } from '../components/Checkbox';
import { ThirdPartyDataConsentPopup } from '../components/ThirdPartyDataConsentPopup';
import { TermsOfServiceConsentPopup } from '../components/TermsOfServiceConsentPopup';
import { PrivacyPolicyPopup } from '../components/PrivacyPolicyPopup';
import { useQueryClient } from 'react-query';

interface FormValues {
  dataCollectionConsent: boolean;
  thirdPartyDataConsent: boolean;
  termsOfServiceConsent: boolean;
}

export const AddNewConsentByUserPage = () => {
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const {
    watch,
    register,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({});

  const [loading, setLoading] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [privacyPolicyModalOpen, setPrivacyPolicyModalOpen] = useState(false);
  const [termsOfServiceModalOpen, setTermsOfServiceModalOpen] = useState(false);
  const [thirdPartyDataModalOpen, setThirdPartyDataModalOpen] = useState(false);

  const allConsents = watch([
    'termsOfServiceConsent',
    'thirdPartyDataConsent',
    'dataCollectionConsent',
  ]);

  useEffect(() => {
    const allChecked = Object.values(allConsents).every(
      (value) => value === true,
    );
    if (allChecked !== isAllChecked) {
      setIsAllChecked(allChecked);
    }
  }, [allConsents, isAllChecked]);

  const handleAllCheckChange = (checked: boolean) => {
    setValue('termsOfServiceConsent', checked);
    setValue('thirdPartyDataConsent', checked);
    setValue('dataCollectionConsent', checked);
  };

  return (
    <>
      <PrivacyPolicyPopup
        open={privacyPolicyModalOpen}
        onClose={() => setPrivacyPolicyModalOpen(false)}
      />

      <TermsOfServiceConsentPopup
        open={termsOfServiceModalOpen}
        onClose={() => setTermsOfServiceModalOpen(false)}
      />

      <ThirdPartyDataConsentPopup
        open={thirdPartyDataModalOpen}
        onClose={() => setThirdPartyDataModalOpen(false)}
      />

      <Section className="max-w-md">
        <form
          className="space-y-6"
          onSubmit={handleSubmit((data) => {
            setLoading(true);

            api
              .patch('users/me/new-consent', data)
              .then(() => {
                queryClient.invalidateQueries('/users/me');
                push('/');
              })
              .catch((error) => {
                setLoading(false);
                alert(error.response.data.message);
              });
          })}
        >
          <H1>Consent Update</H1>
          <p>
            Please review the newly added consent terms below. Accepting them is
            required to continue using our services.
          </p>

          <div className="pb-24">
            <div className="flex space-x-2">
              {/* 전체 동의 */}
              <Checkbox
                className="rounded-full"
                checked={isAllChecked}
                onChange={(e) => {
                  setIsAllChecked(e.target.checked);

                  handleAllCheckChange(e.target.checked);
                }}
              />
              <label>Agree to All</label>
            </div>

            <div className="mt-2">
              <div className="flex items-center space-x-2 ml-6">
                <Checkbox
                  className="rounded-full"
                  helper={errors.termsOfServiceConsent?.message}
                  {...register('termsOfServiceConsent', {
                    required: 'Please accept privacy policy',
                  })}
                />
                <label
                  className="ml-6 underline"
                  onClick={() => setTermsOfServiceModalOpen(true)}
                >
                  {/* Iakl 홈페이지 이용약관 (필수) */}
                  Terms of Service for the International Association of Korean
                  Lawyers (Required)
                </label>
              </div>

              <div className="flex items-center space-x-2 mt-3 ml-6">
                <Checkbox
                  className="rounded-full"
                  helper={errors.dataCollectionConsent?.message}
                  {...register('dataCollectionConsent', {
                    required: 'Please accept privacy policy',
                  })}
                />
                <label
                  className="ml-6 underline"
                  onClick={() => setThirdPartyDataModalOpen(true)}
                >
                  {/* 개인정보 수집 및 이용 동의 (필수) */}
                  Consent to the Collection and Use of Personal Information
                  (Required)
                </label>
              </div>
              <div className="flex items-center space-x-2 mt-3 ml-6">
                <Checkbox
                  className="rounded-full"
                  helper={errors.thirdPartyDataConsent?.message}
                  {...register('thirdPartyDataConsent', {
                    required: 'Please accept privacy policy',
                  })}
                  littleTop={true}
                />
                <button
                  type="button"
                  className="ml-6 underline"
                  onClick={() => setPrivacyPolicyModalOpen(true)}
                >
                  {/* 개인정보 처리방침 (필수) */}
                  IAKL Privacy Policy (Required)
                </button>
              </div>
            </div>
          </div>
          <Button
            text="Agree"
            disabled={!isAllChecked}
            className="w-full filled-brand-1"
          />
        </form>
      </Section>
    </>
  );
};
